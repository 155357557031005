<template>
  <div>
    <div class="col-12" style="padding-top:30px !important">
      <!-- 허가서 첨부파일 -->
      <c-upload 
        :attachInfo="attachInfo"
        :editable="editable"
        label="LBL0000302">
      </c-upload>
    </div>
    <div class="col-12" style="padding-top:30px !important">
      <!-- 작업계획서 -->
      <c-upload 
        :attachInfo="attachInfoJobPlan"
        :editable="editable"
        label="LBL0000303">
      </c-upload>
    </div>
    <div class="col-12" style="padding-top:30px !important">
      <!-- 교육일지 -->
      <c-upload 
        :attachInfo="attachInfoEducationJournal"
        :editable="editable"
        label="LBL0000304">
      </c-upload>
    </div>
  </div>
</template>

<script>
export default {
  name: 'swp-attach',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
        }
      }
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    saveCall: {
      type: Object,
      default: function() {
        return {
          saveCallData: '',
        }
      }
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SWP_RELATE_INFO',
        taskKey: '',
      },
      attachInfoJobPlan: {
        isSubmit: '',
        taskClassCd: 'SWP_JOB_PLAN',
        taskKey: '',
      },
      attachInfoEducationJournal: {
        isSubmit: '',
        taskClassCd: 'SWP_EDUCATION_JOURNAL',
        taskKey: '',
      },
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'workPermit.sopWorkPermitId'() {
      this.setTaskKey();
    },
    'saveCall.saveCallData'() {
      this.attachInfo.isSubmit = this.$_.clone(this.saveCall.saveCallData)
      this.attachInfoJobPlan.isSubmit = this.$_.clone(this.saveCall.saveCallData)
      this.attachInfoEducationJournal.isSubmit = this.$_.clone(this.saveCall.saveCallData)
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      this.setTaskKey();
    },
    setTaskKey() {
      let taskKey = this.workPermit && this.workPermit.sopWorkPermitId ? this.workPermit.sopWorkPermitId : '';
      this.$set(this.attachInfo, 'taskKey', taskKey)
      this.$set(this.attachInfoJobPlan, 'taskKey', taskKey)
      this.$set(this.attachInfoEducationJournal, 'taskKey', taskKey);
    }
  }
};
</script>
